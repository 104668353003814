import { useInView } from 'react-intersection-observer';
import './css/selo.css';
import seloImage from '../assets/selo.png';



function Selo() {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    return (
        <div className="selo-box">
        <section id="selo">
                <div ref={ref} className={`descriptionselo ${inView ? 'visible' : ''}`}>
                <h2 className="titleselo">GARANTIA</h2>
            </div>
            <div className="text-and-image">
                <img src={seloImage} alt="Descrição da Imagem" className="selo-image" />
                <ul>
                    <li className='garantia'>FIQUE TRANQUILO SEU RISCO É ZERO!</li><br/>
                    <li>O código de defesa consumidor (Art. 49) garante 7 dias para solicitar reembolso em caso de insatisfação com o produto.</li>
                </ul>
            </div>
            
            
        </section>
        </div>
    );
}
export default Selo;
