import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "https://lhf.lfagundes.com.br/api";

export const postConsent = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/consent/register-consent`, data);
        return response.data;
    } catch (error) {
        console.error('Error posting consent:', error);
        throw error;
    }
};

export const sendBonus = async (formData) => {
    try {
        const response = await axios.post(`${API_URL}/bonus/send-bonus`, formData);
        return response.data;
    } catch (error) {
        console.error('Error in sendBonus:', error);
        throw error;
    }

};

export const initializeAndTrackFacebookPixel = async (eventData) => {
    try {
        const response = await axios.post(`${API_URL}/conversion/facebook-conversion`, eventData);
        return response.data;
    } catch (error) {
        console.error('Error in sendEventToBackend:', error);
        throw error;
    }
};




