import React from 'react';
import { useInView } from 'react-intersection-observer';




import './css/compra.css';
import { FaDollarSign, FaClipboard, FaRocket } from 'react-icons/fa';

function Compra() {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const items = [
        {
            icon: <FaClipboard />,
            title: 'FORMULÁRIO',
            description: 'Assim que você realizar o preenchimento de seus dados, clique no botão "comprar agora".'
        },
        {
            icon: <FaDollarSign />,
            title: 'PAGAMENTO',
            description: 'Ao escolher a nossa oferta você será redirecionado para o nosso checkout que é por onde o pagamento será processado, preencha com seus dados reais e efetue o pagamento para contratar o nosso produto.'
        },
         {
            icon: <FaRocket />,
            title: 'ENTREGA',
            description: 'Assim que você efetuar o pagamento será enviado um e-mail com acesso da nossa área de membros, por lá você vai ter acesso ao ebook.'
        }
    ];

    return (
        <div className="compra-container">
            <section id='compra'>
            <div ref={ref} className={`descriptioncompra ${inView ? 'visible' : ''}`}>
                <h1 className="titlecompra">EU QUERO COMO FUNCIONA?</h1>
            </div>
            {items.map((item, index) => (
                <div key={index} className="compra-item">
                    <div className="icon">{item.icon}</div>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                </div>
            ))}
            </section>
        </div>
        
    );
}

export default Compra;
