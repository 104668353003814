import { useState, useEffect } from 'react';
import { postConsent } from '../../src/services/APIservice'; // Certifique-se de que a função postConsent está corretamente definida

import '../function/css/CookieConsent.css';

const CookieConsent = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        // Verifique se o consentimento de cookies já foi dado
        if (!localStorage.getItem('cookieConsent')) {
            setShowModal(true);
        }
    }, []);

    async function acceptCookies () {
        setShowModal(false);
        localStorage.setItem('cookieConsent', 'true');

        try {
            const response = await postConsent({ consent: true });
            console.log('Consent registered:', response); // Log de depuração
        } catch (error) {
            console.error('Error registering consent:', error);
        }
    };

    return (
        showModal && (
            <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                    <p>Utilizamos cookies para melhorar sua experiência. Você aceita?</p>
                    <button onClick={acceptCookies}>Aceitar</button>
                </div>
            </div>
        )
    );
};

export default CookieConsent;
