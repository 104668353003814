import React, { useState } from 'react';
import './css/FAQ.css';


function FAQ() {
    const [visibleAnswer, setVisibleAnswer] = useState(null);

    const toggleAnswer = (index) => {
        setVisibleAnswer(visibleAnswer === index ? null : index);
    };

    const faqData = [
        {
            question: 'Como vou receber ese material?',
            answer: 'Em seu e-mail de compra! O acesso é imediato logo após a confirmação do pagamento. Se pagar no Boleto pode levar até 3 dias úteis para a compensação.'
        },
        {
            question: 'Qual é a política de devolução?',
            answer: 'Nossa política de devolução permite que você devolva os produtos em até 7 dias após a compra.'
        },
        {
            question: 'Em quanto tempo vou ter resultado com o material?',
            answer: "Você pode começar a ver os resultados com extrema rapidez assim que comecar a aplicar os metodos… Em alguns dias depois de começar. Quanto mais você fizer disso parte de sua rotina diária, mais rápido você vai obter os resultados que deseja."
        },
        {
            question: 'Quais são as opções de pagamento disponíveis?',
            answer: 'Aceitamos cartões de crédito, débito e pagamentos via PayPal.'
        },
    ];

    return (
        <div className="faq-background">
            <div className="faq-container">
                <h2>Perguntas Frequentes</h2>
                {faqData.map((item, index) => (
                    <div key={index} className="faq-item" onClick={() => toggleAnswer(index)}>
                        <p>{item.question}</p>
                        {visibleAnswer === index && (
                            <div className="faq-answer-container">
                                <hr />
                                <div className="faq-answer">{item.answer}</div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQ;
