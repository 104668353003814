import React from 'react';
import { useInView } from 'react-intersection-observer';
import './css/plan.css';
import riquezaImage from '../assets/riqueza.png';
import conhecimentoImage from '../assets/conhecimento.png';
import habilidadesImage from '../assets/habilidades.png';

function Plan() {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <div className="plan-container">
            <div ref={ref} className={`description ${inView ? 'visible' : ''}`}>
                <h2 className="title">Se você tem objetivos nós temos um plano para você</h2>
            </div>

            <div className="mentors">
                <div className="mentor-item">
                    <img src={riquezaImage} alt="Mentalidade" className="mentor-icon" />
                    <p>Riqueza</p>
                </div>
                <div className="mentor-item">
                    <img src={conhecimentoImage} alt="Prosperidade" className="mentor-icon" />
                    <p>Conhecimento</p>
                </div>
                <div className="mentor-item">
                    <img src={habilidadesImage} alt="Riqueza" className="mentor-icon" />
                    <p>habilidades</p>
                </div>
            </div>

            <p className="mentors-description">São alguns dos melhores caminhos para te entregar as melhores estratégias e técnicas para você descobrir o segredo que está por trás.</p>

            <div className="goals">
                <div className="goal-item">
                    <p>Quer aumentar seus ganhos? Ou recuperar o que foi perdido? Temos a solução perfeita para você!</p>
                </div>
                <div className="goal-item">
                    <p>Desvende e desbloqueie o segredo para manter seus lucros e aumentar suas chances de sucesso!</p>
                </div>
                <div className="goal-item">
                    <p>Quer aprender uma das melhores habilidades e tecnicas? Descobrir as estrategias e quando aplicar?</p>
                </div>
            </div>

            <div className="final-message">
                <p>Você pode continuar tentando fazer sozinho ou garantir resultados nunca vistos antes, com umas das melhores técnicas e estratégias.</p>
            </div>

            <button className="cta-button" onClick={() => window.location.href = 'https://pay.kiwify.com.br/XrDqM7p'}>BAIXAR O MANUAL DE ESTRATEGIAS </button>
        </div>
    );
}

export default Plan;
