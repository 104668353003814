import React from 'react';
import { createRoot } from 'react-dom/client'; // Importação correta do createRoot

import './index.css';
import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Importação do Font Awesome


const container = document.getElementById('root');
const root = createRoot(container); // Crie a raiz
root.render(<App />); // Renderize o componente App