import { useInView } from 'react-intersection-observer';
import './css/content.css';


import amostraVideo from '../assets/amostra.mp4';
import React, { useRef, useEffect } from 'react';

function Content() {
    const videoRef = useRef(null);
    

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
           
        }
    }, []);

    const handleVideoClick = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
             
            } else {
                videoRef.current.pause();
             
            }
        }
    };

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <section id="content">
            <div ref={ref} className={`descriptioncontent ${inView ? 'visible' : ''}`}>
                <p className="titlecontent">O QUE VOCE VAI APRENDER</p>
            </div>
            <div className="textvideo-ande-imagem">
                <h2>DESBLOQUEANDO O CÓDIGO DO SUCESSO</h2>
                <div className="video-container">
                    <video
                        ref={videoRef}
                        onClick={handleVideoClick}
                        autoPlay
                        muted
                        loop  // Adicionado aqui para fazer o vídeo repetir
                        className="content-video"
                        style={{ width: '100%', height: 'auto' }}
                        controls={false} // Desativa controles padrão
                        disablePictureInPicture
                    >
                        <source src={amostraVideo} type="video/mp4" />
                        Seu navegador não suporta o elemento de vídeo.
                    </video>
                </div>
                <div className="text-content">
                    <h6>Compreendendo o Jogo</h6>
                    <ul>
                        <li>Você aprenderá sobre a mecânica básica do Jogo, incluindo os símbolos e linhas de pagamento, além da psicologia das apostas, destacando a importância do autocontrole para evitar decisões impulsivas.</li>
                    </ul>
                    <h4>Estratégias de Apostas</h4>
                    <ul>
                        <li>Exploraremos a Técnica da Dobra e outras estratégias de avaliação da banca e testes de diferentes valores de apostas.</li>
                    </ul>
                    <h3>Gestão de Ganhos e Perdas</h3>
                    <ul>
                        <li>Focaremos na valorização dos ganhos, estrutura financeira do jogador e metodologias para maximizar ganhos a longo prazo enquanto evitamos grandes perdas com pausas estratégicas e aumentos graduais de apostas.</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Content;
