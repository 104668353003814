
import Header from '../src/pages/header';
import Intro from '../src/pages/intro';
import Content from '../src/pages/content';
import Selo from '../src/pages/selo';
import FAQ from '../src/pages/FAQ';
import Footer from '../src/pages/footer';
import Plan from '../src/pages/plan';
import Compra from '../src/pages/compra';
import Testimonials from '../src/pages/Testimonials';
import PromotionBox   from '../src/pages/promotionBox';
import BonusModal from '../src/function/BonusModal';
import CookieConsent from '../src/function/CookieConsent';
import {useState} from 'react';
import './App.css';

import React from 'react';

function App  ()  {
    // const [showBonusModal, setShowBonusModal] = useState(false);
    // useEffect(() => {
    //     // Inicializa o Facebook Pixel
    //     initializeFacebookPixel('469975332420850');

    // }, []); 

    const [showBonusModal, setShowBonusModal] = useState(false);
    
    return (
        <div>
            <CookieConsent />
            <Header />
            <Intro />
            <Content />
            <Plan />
            <Testimonials />
            <FAQ />
            <Compra />
            <PromotionBox />
            <Selo />
            <Footer />
            <BonusModal showModal={showBonusModal} setShowModal={setShowBonusModal} />
        </div> 
    );
}

export default App;
