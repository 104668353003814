import React from 'react';

function Footer() {
    return (
        <footer>
            <p>Lucas © 2024. Todos os direitos reservados.</p>
            <button 
                className="footer-link" 
                onClick={() => { /* Ação ao clicar */ }}
            >
                Política de Privacidade
            </button>
        </footer>
    );
}

export default Footer;
