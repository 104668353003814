import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/testimonials.css';


import maloteImage from '../assets/malote.jpg';
import marcaradoImage from '../assets/marcarado.jpg';
import decolagemImage from '../assets/decolagem.jpg';
import formulaImage from '../assets/formula.jpg';

const testimonials = [
  {
    image: maloteImage,
   
  },
  {
    image: marcaradoImage,
    
  },
  {
    image: decolagemImage,
    
  },
  {
    image: formulaImage,
    
  },
  // Adicione mais depoimentos conforme necessário
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="testimonials-container">
      <h5>DOMINE O JEITO MAIS PODEROSO E FACIL DE JOGAR</h5>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <img src={testimonial.image} alt="Testimonial" className="testimonial-image" />
            <p className="testimonial-text">{testimonial.text}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
