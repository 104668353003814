import { useState, useEffect } from 'react';
import {sendBonus } from '../services/APIservice'; // Certifique-se de que a função postConsent está corretamente definida
import '../function/css/BonusModal.css';

const BonusModal = ({ showModal, setShowModal }) => {
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        // Verifique se o consentimento de cookies já foi dado
        if (!localStorage.getItem('bonusModal')) {
            setShowModal(true);
        }
    }, [setShowModal]);

    async function claimBonus() {
        setShowModal(false);
        setShowForm(true);
        localStorage.setItem('bonusModal', 'true');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            name: e.target.name.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
        };

        try {
            const response = await sendBonus(formData);
            console.log('Success:', response);
            window.location.href = 'https://main.d3201hbrvo79gp.amplifyapp.com/';
            
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p>Parabéns! Você ganhou um mega bônus gratuito. Clique aqui para receber.</p>
                        <button onClick={claimBonus}>Receber Bônus</button>
                    </div>
                </div>
            )}
            {showForm && (
                <div className="modal">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name">Nome:</label>
                            <input type="text" id="name" name="name" required /><br /><br />
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" required /><br /><br />
                            <label htmlFor="phone">Telefone:</label>
                            <input type="text" id="phone" name="phone" required /><br /><br />
                            <button type="submit">Enviar</button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default BonusModal;
