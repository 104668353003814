import React, { useRef, useEffect, useState } from 'react';
import './css/intro.css';
import debateVideo from '../assets/debate.mp4';
import fundoImage from '../assets/fundo.jpg';


function Intro() {
    const videoRef = useRef(null);
    const [videoEnded, setVideoEnded] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const [showUnmuteMessage, setShowUnmuteMessage] = useState(true);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    }, []);

    const handleVideoEnd = () => {
        setVideoEnded(true);
        setIsPlaying(false);
    };

    const handleVideoClick = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsPlaying(true);
            } else {
                videoRef.current.pause();
                setIsPlaying(false);
            }
        }
    };

    const replayVideo = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
            setVideoEnded(false);
            setIsPlaying(true);
        }
    };

    const unmuteVideo = () => {
        if (videoRef.current) {
            videoRef.current.muted = false;
            videoRef.current.currentTime = 0; // Reinicia o vídeo
            videoRef.current.play();
            setIsMuted(false);
            setShowUnmuteMessage(false);
            setIsPlaying(true);
        }
    };

    return (
        <section id="intro" style={{ backgroundImage: `url(${fundoImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <h2>DESBLOQUEIE O CÓDIGO DO SUCESSO NO JOGO</h2>
            <p>Imagine ter em mãos o segredo para dominar o jogo e faturar como nunca antes. Com nosso manual exclusivo, você terá acesso à lógica oculta e estratégias infalíveis que transformarão completamente sua experiência. Este não é apenas um guia—é o mapa do tesouro que revelará cada detalhe crucial, desde a probabilidades até as técnicas mais avançadas para maximizar seus ganhos e minimizar suas perdas.</p>
            <div className="video-container">
                <video
                    ref={videoRef}
                    onEnded={handleVideoEnd}
                    onClick={handleVideoClick}
                    autoPlay
                    muted={isMuted}
                    className="intro-video"
                    style={{ display: videoEnded ? 'none' : 'block', width: '100%', height: 'auto' }}
                    controls={false} // Desativa controles padrão
                    disablePictureInPicture
                >
                    <source src={debateVideo} type="video/mp4" />
                    Seu navegador não suporta o elemento de vídeo.
                </video>
                {showUnmuteMessage && (
                    <div className="unmute-overlay">
                        <div className="unmute-message">
                            
                            <button onClick={unmuteVideo}>Clique para ouvir</button>
                        </div>
                    </div>
                )}
                
                {!isPlaying && !videoEnded && (
                    <div className="play-overlay" onClick={handleVideoClick}>
                        <div className="play-button">&#9654;</div>
                    </div>
                )}
            </div>
            {videoEnded && (
                <div className="video-end-message">
                    <p>Obrigado por ter assistido até o final!</p>
                    <div className="replay-option" onClick={replayVideo}>
                        <div className="replay-icon">&#8634;</div>
                        <p>Assistir novamente</p>

                    </div>
                </div>
            )}
             <h3>Não fique apenas assistindo os outros ganharem. Desbloqueie o potencial máximo do jogo e prepare-se para faturar alto!</h3>

            <button
                className="cta-button"
                onClick={() => window.location.href = 'https://pay.kiwify.com.br/XrDqM7p'}
            >
                BAIXAR O MANUAL DE ESTRATEGIAS            </button>
        </section>
    );
}

export default Intro;
