import React, { useState, useEffect } from 'react';
import './css/promotionBox.css';
import visaImage from '../assets/visa.png';
import masterImage from '../assets/master.png';
import hiperImage from '../assets/hiper.png';
import eloImage from '../assets/elo.png';
import boletoImage from '../assets/boleto.png';
import payImage from '../assets/pay.png';
import americanImage from '../assets/american.png';

function PromotionBox() {
    const [minutes, setMinutes] = useState(15);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (minutes > 0) {
                setMinutes(minutes - 1);
                setSeconds(59);
            } else {
                clearInterval(countdown);
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds]);

    return (
        <div className="promotion-box">
            <div className="offer">OFERTA IMPERDÍVEL</div>
            <div className="timer">
                <div className="time-box">
                    <span className="time-value">{minutes < 15 ? `${minutes}` : minutes}</span>
                    <span className="time-label">MINUTOS</span>
                </div>
                <div className="colon">:</div>
                <div className="time-box">
                    <span className="time-value">{seconds < 10 ? `0${seconds}` : seconds}</span>
                    <span className="time-label">SEGUNDOS</span>
                </div>
            </div>
            <div className="price-wrapper">
                <div className="price">
                  <div className="discount">
                   Poderia facilmente custar mais de R$49,99 <br /><br />
                   </div>
                    Mas você tem a oportunidade de estar entre os melhores por apenas
                    <p className='parcela'>Por apenas 3x de</p>
                    <h2 className="new-price">R$: 5,97</h2>
                    <p className="avista">Ou a vista R$: 16,89</p>
                </div>
            </div>
            <button className="buy-now" onClick={() => window.location.href = 'https://pay.kiwify.com.br/XrDqM7p'} >COMPRAR AGORA</button>
            <div className="payment-methods">
                <img src={visaImage} alt="Visa" />
                <img src={masterImage} alt="Master" />
                <img src={hiperImage} alt="Hiper" />
                <img src={eloImage} alt="Elo" />
                <img src={payImage} alt="Pay" />
                <img src={americanImage} alt="American" />
                <img src={boletoImage} alt="Boleto" />
            </div>
        </div>
    );
}

export default PromotionBox;
